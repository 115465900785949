@import '../../styles/propertySets.css';

.root {
}

.contentParent{
  height: 10px;
  overflow: hidden;
  transition: height ease 1s;
  position: sticky;
}

.content{ /* Fixed on top of everything */
  position: relative;
  top: 0;
  z-index: 2000;

  /* Dimensions */
  width: 100vw;
  padding: 5px 0;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorNegative);

  /* Cover Topbar on desktop */
  @media (--viewportMedium) {
    padding: 0px;
  }
}



.message {
  @apply --marketplaceH5FontStyles;
  margin-left: 24px;
  line-height: 24px;
}

.closeBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 0 4px;
  margin: 0 24px 0 16px;

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;
  

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin: 0 24px 0 15px;
    line-height: 24px;
  }
}
